<template>
  <div>
    <hr v-if="isShowHr" class="d-none d-lg-block" />
    <Spinner v-if="isLoading" class="mt-4" />
    <div
      :class="
        'section-content flex-column' + (vipInfo && vipInfo.is_shop && ticketInfo.status == 1 ? ' full-height' : '')
      "
      v-else
    >
      <b-card class="content-card mb-3">
        <div class="ticket-summary">
          <div class="game-image" v-if="ticketInfo.order_type !== 'keno_bulk'">
            <img :src="ticketInfo.game_img" alt />
          </div>
          <div v-else class="order-note text-uppercase flex-md-shrink-0">
            <span v-if="getProductName()">
              <span>{{ getProductName() }}</span>
              <span>&nbsp;-&nbsp;</span>
            </span>
            {{ ticketDetail.length }} BỘ SỐ
          </div>
          <div class="order-detail-block d-flex">
            <template v-if="isTraditional">
              <div class="order-detail d-none d-md-block">
                <div class="order-info">
                  <span class="text-nowrap">
                    <span v-if="getProductName()">
                      <span>Vé số kiến thiết {{ getProductName() }} </span>
                    </span>
                  </span>
                  <br />
                  <em
                    >{{ formatDate(ticketInfo.lottery_time, 'dddd ngày DD/MM/YYYY') }}
                    <template v-if="ticketDetail[0].ticket_info.is_group">
                      <span>(Cặp nguyên <strong>110</strong> vé)</span>
                    </template>
                    <template v-else>
                      <span
                        >(<strong>{{ ticketDetail[0].ticket_info.quantity }}</strong> vé)</span
                      >
                    </template>
                  </em>
                  <br />
                  <span>
                    <img src="@/assets/img/icon/icon_ticket.svg" alt="" style="margin-right: 5px" />
                    {{ orderInfo.shipping_method.name }}
                  </span>
                </div>
              </div>
            </template>
          </div>
          <div
            class="ticket-img px-sm-3 d-none d-md-flex custom-height w-47 justify-content-center"
            v-if="ticketInfoTicketImg[0] && ticketInfoTicketImg[0].src !== '' && ticketInfoTicketImg[0].src !== null"
          >
            <a class="pointer d-inline-block position-relative groupThumb" @click="openGallery()">
              <div
                class="groupThumb__img customGroupThumb"
                :style="{
                  'background-image': 'url(' + ticketInfoTicketImg[0].src + ')'
                }"
              ></div>
              <div class="groupThumb__iconZoom">
                <img width="20" height="21" src="../../assets/img/icon/icon_zoom.svg" />
              </div>
            </a>
          </div>

          <div class="ticket-info text-right ml-auto flex-md-shrink-0">
            <div class="ticket-price">{{ formatPrice(ticketInfo.total) }}</div>
          </div>
        </div>
        <div class="d-md-none mb-2">
          <template v-if="isTraditional">
            <div class="order-info">
              <span class="text-nowrap">
                <span v-if="getProductName()">
                  <span> Vé số kiến thiết {{ getProductName() }}</span>
                </span>
              </span>
              <br />
              <em
                >{{ formatDate(ticketInfo.lottery_time, 'dddd DD/MM/YYYY') }}
                <template v-if="ticketDetail[0].ticket_info.is_group">
                  <span>(Cặp nguyên <strong>110</strong> vé)</span>
                </template>
                <template v-else>
                  <span
                    >(<strong>{{ ticketDetail[0].ticket_info.quantity }}</strong> vé)</span
                  >
                </template>
              </em>
              <br />
              <span>
                <img src="@/assets/img/icon/icon_ticket.svg" alt="" style="margin-right: 5px" />
                {{ orderInfo.shipping_method.name }}
              </span>
            </div>
          </template>
        </div>
        <div
          class="ticket-img px-sm-3 d-md-none customWidth custom-height"
          v-if="ticketInfoTicketImg[0] && ticketInfoTicketImg[0].src !== '' && ticketInfoTicketImg[0].src !== null"
        >
          <a class="pointer d-inline-block position-relative groupThumb" @click="openGallery()">
            <div
              class="groupThumb__img customGroupThumb"
              :style="{
                'background-image': 'url(' + ticketInfoTicketImg[0].src + ')'
              }"
            ></div>
            <div class="groupThumb__iconZoom">
              <img width="20" height="21" src="../../assets/img/icon/icon_zoom.svg" />
            </div>
          </a>
        </div>
        <template v-if="isTraditional">
          <div class="traditionalNumber">
            <span class="prefix" v-if="ticketDetail[0].ticket_info.is_group">x</span
            ><span>{{
              ticketDetail[0].ticket_info.is_group ? `${groupTicketNumber}` : ticketDetail[0].ticket_info.value
            }}</span>
            <img
              @click="openGroupTicketDetail"
              src="@/assets/img/icon/icon_info-big.svg"
              alt
              class="title-btn customIcon"
              v-if="ticketDetail[0].ticket_info.is_group"
            />
          </div>
          <div class="traditionalCount" v-if="traditionalCountDown">
            Đang chờ xổ số
            <span v-if="getProductName()">
              <span>{{ getProductName() }}</span>
            </span>
            lúc
            {{ formatDate(ticketInfo.lottery_time, 'HH:mm dddd DD/MM/YYYY') }}
            <br />
            <div>
              Còn <span class="countdown">{{ traditionalCountDown }}</span> nữa!
            </div>
          </div>
        </template>
        <template>
          <div class="traditionalReward" v-if="hasReward">
            <div class="text-center">
              <div class="mb-4">
                <img src="@/assets/img/thantai-trungthuong.png" alt="" width="133" />
              </div>
              Chúc mừng bạn đã trúng thưởng
              {{ rewards.length === 1 ? rewards[0].title : '' }}
              <div class="traditionalReward__total">
                {{ formatPrice(totalPrize) }}
              </div>
            </div>
            <table width="100%" v-if="rewards.length > 1">
              <tr v-for="reward in rewards" :key="reward.title">
                <td width="30%">{{ reward.title }}</td>
                <td width="30%" class="text-center">
                  <span v-for="val in reward.value" :key="val" class="font-weight-bold">{{ val }}</span>
                </td>
                <td width="30%" class="traditionalReward__amount">
                  {{ formatPrice(reward.amount) }}
                </td>
              </tr>
            </table>
          </div>
          <b-card class="content-card mt-2 text-center" v-if="!traditionalCountDown">
            <div class="resultTitle mb-4">
              Kết quả xổ số
              <span v-if="getProductName()">
                <span>{{ getProductName() }}</span>
              </span>
              ngày {{ formatDate(ticketInfo.lottery_time, 'DD/MM/YYYY') }}
            </div>
            <div v-if="ticketResult.length === 0">
              <Spinner class="my-4" />
              Đang cập nhật kết quả
            </div>
            <div class="resultTraditional" v-else>
              <table width="100%">
                <tr>
                  <th class="text-left">Giải</th>
                  <th>Kết quả quay số</th>
                  <th class="resultTraditional__reward">Giải thưởng</th>
                </tr>
                <tr v-for="(result, index) in reverseTicketResult" :key="`result-${index}`">
                  <td class="resultTraditional__name">Giải {{ index === 8 ? 'Đặc Biệt' : 8 - index }}</td>
                  <td class="resultTraditional__numberrow">
                    <span class="resultTraditional__number" v-for="(num, i) in result" :key="`num-${index}-${i}`">
                      {{ num }}
                    </span>
                  </td>
                  <td class="resultTraditional__reward">
                    {{ traditionalPriceList[index] }}
                  </td>
                </tr>
              </table>
            </div>
          </b-card>
        </template>
      </b-card>
    </div>
    <div v-if="!isLoading">
      <HLightBox
        :ref="`hLightbox_img_${this.ticketInfo.id}`"
        :data="{
          ...ticketInfo,
          ...orderInfo,
          ticketDetail,
          ...ticketInfoPeriods[currentHlBoxIndex]
        }"
        :ticketCustomResult="ticketCustomResult"
        :ticketMapping="ticketMapping"
        :media="[ticketInfoTicketImg[currentHlBoxIndex], ticketInfoBackImageUrl[currentHlBoxIndex]]"
        :show-light-box="false"
        :show-thumbs="false"
        :isPower="isPower"
        :select="0"
        :gameType="gameType"
        :canPrev="currentHlBoxIndex > 0"
        :canNext="currentHlBoxIndex < ticketInfoPeriods.length - 1"
        @prevPeriod="handlePrevPeriod"
        @nextPeriod="handleNextPeriod"
      ></HLightBox>
    </div>

    <b-modal
      headerClass="border-bottom-0 trans-detail-modal"
      modal-class="modalResponsive"
      v-model="isOpenmodalTransaction"
      :hide-footer="true"
      size="lg"
    >
      <template v-slot:modal-title>
        <span class="modal-title">Chi tiết vé #{{ ticketInfo.display_ticket_id }}</span>
      </template>
      <div class="transaction-info">
        <div class="transaction-info-row d-flex">
          <div class="transaction-info-item f-1">
            <div class="item-title">KỲ QUAY SỐ</div>
            <div class="item-value">#{{ ticketInfo.period_no }}</div>
          </div>
          <div class="transaction-info-item f-2">
            <div class="item-title">HÌNH THỨC</div>
            <div class="item-value">
              {{ orderInfo.shipping_method ? orderInfo.shipping_method.name : '' }}
            </div>
          </div>
        </div>
        <div class="transaction-info-row d-flex">
          <div class="transaction-info-item f-1">
            <div class="item-title">THỜI GIAN ĐẶT VÉ</div>
            <div class="item-value">
              {{ getFulltime(orderInfo.created_at, 'DD/MM/YYYY HH:mm:ss') }}
            </div>
          </div>
          <div class="transaction-info-item f-1">
            <div class="item-title">THỜI GIAN BÁN VÉ</div>
            <div class="item-value">
              {{ ticketInfo.printed_at ? getFulltime(ticketInfo.printed_at, 'DD/MM/YYYY HH:mm:ss') : '' }}
            </div>
          </div>
          <div class="transaction-info-item f-1">
            <div class="item-title">HÌNH THỨC THANH TOÁN</div>
            <div class="item-value">
              {{ orderInfo.payment_method ? orderInfo.payment_method.name : '' }}
            </div>
          </div>
        </div>
        <div class="transaction-info-row d-flex">
          <div class="transaction-info-item f-1">
            <div class="item-title">TIỀN VÉ</div>
            <div class="item-value">{{ formatPrice(ticketInfo.total) }}</div>
          </div>
          <div class="transaction-info-item f-1">
            <div class="item-title">PHÍ GIAO DỊCH</div>
            <div class="item-value">
              {{
                formatPrice(
                  (parseFloat(orderInfo.payment_fee) + parseFloat(orderInfo.shipping_fee)) *
                    (parseFloat(ticketInfo.total) / parseFloat(orderInfo.product_total_price))
                )
              }}
            </div>
          </div>
          <div class="transaction-info-item f-1">
            <div class="item-title">TỔNG TIỀN</div>
            <div class="item-value">
              {{
                formatPrice(
                  parseFloat(ticketInfo.total) +
                    (parseFloat(orderInfo.payment_fee) + parseFloat(orderInfo.shipping_fee)) *
                      (parseFloat(ticketInfo.total) / parseFloat(orderInfo.product_total_price))
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="receiver-title">Thông tin người nhận</div>
      <div class="receiver-info d-flex">
        <div class="transaction-info-item f-1">
          <div class="item-title">NGƯỜI NHẬN</div>
          <div class="item-value">{{ orderInfo.receiver_name }}</div>
        </div>
        <div class="transaction-info-item f-1">
          <div class="item-title">SỐ ĐIỆN THOẠI</div>
          <div class="item-value d-flex justify-content-end d-md-block">
            <CensorData :data="orderInfo.receiver_phone" />
          </div>
        </div>
        <div class="transaction-info-item f-1">
          <div class="item-title">SỐ CMND/CCCD</div>
          <div class="item-value">{{ orderInfo.receiver_id }}</div>
        </div>
      </div>
    </b-modal>
    <b-modal
      headerClass="border-bottom-0 trans-detail-modal"
      modal-class="modalRegister modalRegisterRep"
      v-model="modelPrint"
      :hide-footer="true"
    >
      <template v-slot:modal-title>
        <span class="modal-title">Biên nhận #{{ ticketInfo.display_ticket_id }}</span>
      </template>
      <Spinner v-show="imageLoading" class="py-3" />
      <div class="display-image">
        <div id="bill_img"></div>
      </div>
      <div class="image-footer text-right">
        <b-button-group class="w-100 btn-group-justified">
          <b-button class="btnPrint" v-if="canPrint" :disabled="imageLoading" @click="printImage">IN</b-button>
          <b-button class="btnPrint" v-if="canSave" :disabled="imageLoading" @click="saveImage">LƯU</b-button>
          <b-button class="btnPrint" v-if="canShare" :disabled="imageLoading" @click="shareImage">CHIA SẺ</b-button>
        </b-button-group>
      </div>
    </b-modal>
    <div id="print_img"></div>
    <div id="invoice" v-if="vipInfo && vipInfo.is_shop && !isLoading && ticketInfo.status == 1">
      <div class="shop-info">
        <div class="shop-info__name">{{ vipInfo.shop_name }}</div>
        <div class="shop-info__address">{{ vipInfo.shop_address }}</div>
        <div class="shop-info__phone">ĐT: {{ formatPhone(vipInfo.shop_phone) }}</div>
      </div>
      <div class="game-info">
        <div class="game-info__title">BIÊN NHẬN MUA HỘ</div>
        <div class="game-info__ticket">Vé #{{ ticketInfo.display_ticket_id }}</div>
        <div class="game-info__name">
          {{ ticketInfo.game_handler }}
          <span v-if="getPrintProductName()">- {{ getPrintProductName() }}</span>
        </div>
        <div class="game-info__period">
          <strong>Kỳ #{{ ticketInfo.period_no }} - {{ formatDate(ticketInfo.lottery_time, 'HH:mm') }}</strong>
          <br />
          {{ formatDate(ticketInfo.lottery_time, 'DD/MM/YYYY') }}
        </div>
        <div class="game-info__detail">{{ ticketDetail.length }} bộ số</div>
      </div>
      <div class="game-detail">
        <div class="game-detail__row" v-for="(ticket, index) in ticketDetail" :key="'ticket_invoice_' + index">
          <div>
            <strong>{{ ticketMapping[index] }}:</strong>
          </div>
          <div class="game-detail__row--flex1 pl-2">
            <strong>{{ ticket.display_info }}</strong>
          </div>
          <div class="text-right pr-2">{{ ticket.display_type }}</div>
          <div class="text-right" v-if="ticketInfo.game_handler != 'power' && ticketInfo.game_handler != 'mega'">
            {{ formatPrice(ticket.price) }}
          </div>
        </div>
      </div>
      <div class="invoice-info">
        <div class="invoice-info__price">
          <div class="invoice-info__text">Tiền vé</div>
          <div class="invoice-info__text">
            {{ formatPrice(ticketInfo.total) }}
          </div>
        </div>
        <div class="invoice-info__fee">
          <div class="invoice-info__text">Phí giao dịch</div>
          <div class="invoice-info__text">
            {{
              formatPrice(
                (parseFloat(orderInfo.payment_fee) + parseFloat(orderInfo.shipping_fee)) *
                  (parseFloat(ticketInfo.total) / parseFloat(orderInfo.product_total_price))
              )
            }}
          </div>
        </div>
        <div class="invoice-info__total">
          <div class="invoice-info__text--strong">TỔNG CỘNG</div>
          <div class="invoice-info__text--strong">
            {{
              formatPrice(
                parseFloat(ticketInfo.total) +
                  (parseFloat(orderInfo.payment_fee) + parseFloat(orderInfo.shipping_fee)) *
                    (parseFloat(ticketInfo.total) / parseFloat(orderInfo.product_total_price))
              )
            }}
          </div>
        </div>
      </div>
    </div>
    <CommonModal
      @updateOpen="isOpenGroupTicketDetail = $event"
      dialogClass="popupGroupTicketDetail"
      :isOpen="isOpenGroupTicketDetail"
      :title="`Vé cặp nguyên x${groupTicketNumber}`"
      :isHiddenFooter="true"
      @handleHide="handleHideGroupTicketDetail"
    >
      <div v-if="ticketDetail && ticketDetail.length > 0">
        <div class="groupDetail">
          <div v-for="num in 10" :key="num">
            <div class="d-flex align-items-center justify-content-between groupDetail__item text-line-1">
              <span class="font-18 font-weight-600 groupDetail__number">{{ num - 1 }}{{ groupTicketNumber }}</span>
              <span class="">11 vé</span>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  </div>
</template>

<style lang="scss" scoped>
.listPeriodInfo__item__time_custom {
  padding: 0px 0.5rem;
  height: 40px;
  border: 0px solid #dbdbdb;
  border-bottom-width: 1px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.listPeriodInfo__item__price_custom {
  height: 64px;
  padding: 0.675rem;
  .text-bold {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
  }
  .winning {
    color: #d31904;
  }
}
.investStopRuleTitle {
  font-style: normal;
  font-weight: 700;
  color: #333333;
}

.investStopRule {
  font-style: normal;
  font-weight: 400;

  color: #666666;
  b {
    color: #333333;
  }
}

.custom-height {
  height: 60px;
  overflow: hidden;
}
.image-footer {
  padding: 10px 0;
}
#print_img {
  width: 100%;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  font-family: 'Roboto Mono', monospace;
  padding: 10px;
  // margin-left: -20px;
  // margin-right: -20px;
  font-size: 15px;
  strong {
    font-weight: 900;
  }
  .shop-info {
    text-align: center;
    border-bottom: 1px solid black;
    padding: 5px 0;
    &__name {
      font-size: 28px;
      font-weight: 900;
      font-family: 'Exo 2';
    }
  }
  .game-info {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px dotted black;
    &__title {
      font-size: 21px;
      font-weight: 700;
    }
    &__ticket {
      font-weight: 700;
    }
    &__name {
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 21px;
      font-weight: 700;
    }
  }
  .game-detail {
    border-bottom: 1px dotted black;
    padding: 5px 0;
    strong {
      font-size: 16px;
      white-space: pre-wrap;
    }
    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &--flex1 {
        flex: 1;
      }
    }
  }
  .invoice-info {
    padding: 10px 0;
    > div {
      display: flex;
      justify-content: space-between;
    }
    &__total {
      margin-top: 5px;
      font-weight: 900;
      font-size: 20px;
    }
  }
}
.btnPrint,
.image-footer button {
  border-color: #fff !important;
  background-color: #00509d;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 10px;
  &:hover,
  &:focus,
  &:active {
    background-color: #004280 !important;
    color: #fff !important;
  }
  img {
    height: 14px;
    margin-top: -2px;
  }
}
#invoice {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: -1;
  width: 310px;
  background-color: #fff;
  font-family: 'Roboto Mono';
  padding: 10px;
  // margin-left: -20px;
  // margin-right: -20px;
  font-size: 15px;
  strong {
    font-weight: 900;
  }
  .shop-info {
    text-align: center;
    border-bottom: 1px solid black;
    padding: 5px 0;
    &__name {
      font-size: 28px;
      font-weight: 900;
      font-family: 'Exo 2';
    }
  }
  .game-info {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px dotted black;
    &__title {
      font-size: 21px;
      font-weight: 700;
    }
    &__ticket {
      font-weight: 700;
    }
    &__name {
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 21px;
      font-weight: 700;
    }
  }
  .game-detail {
    border-bottom: 1px dotted black;
    padding: 5px 0;
    strong {
      font-size: 16px;
      white-space: pre-wrap;
    }
    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &--flex1 {
        flex: 1;
      }
    }
  }
  .invoice-info {
    padding: 10px 0;
    > div {
      display: flex;
      justify-content: space-between;
    }
    &__total {
      margin-top: 5px;
      font-weight: 900;
      font-size: 20px;
    }
  }
}
.btnPrint,
.image-footer button {
  border-color: #fff !important;
  background-color: #00509d;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 10px;
  &:hover,
  &:focus,
  &:active {
    background-color: #004280 !important;
    color: #fff !important;
  }
  img {
    height: 14px;
    margin-top: -2px;
  }
}
.controlBox {
  padding: 15px;
  text-align: center;

  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;

  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  // z-index: 1025;
  z-index: 999;
  .btnPrint {
    background-color: #00509d;
    padding: 10px;
    line-height: 22px;
    font-size: 15px;
    img {
      height: 18px;
      margin-top: -3px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .section-content {
    display: flex;
    .groupPeriodCustom {
      flex: 1;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .controlBox {
    padding: 0.85rem 1rem;
  }
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .contentBox {
    padding-bottom: 4rem;
  }

  .controlBox {
    position: sticky;
    top: 5rem;
    padding: 20px;
  }
}
.groupPeriodCustom {
  margin-top: 10px;
}
.ticket-status {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 8px;
}
.iconZoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.ticket-price.correct {
  color: #d68c00;
}
.content-card {
  width: 100%;
}
// .order-detail-block {
//   flex: 1;
// }
.ticketItemListNumber {
  width: 40px;
  height: 40px;
  color: $colorNumberText;
  background-color: $colorNumber;
  border-radius: 50%;
  font-size: $fontSizeNumber;
  font-weight: $fontWeightNumber;
  border: 1px solid #dbdbdb !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.3rem 0.3rem 0;
  &.correct {
    background-color: #d68c00;
    border: 1px solid #d68c00;
    color: #fff;
  }
}

.title-btn {
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
  cursor: pointer;
}

.transaction-info {
  .transaction-info-row {
    padding: 5px 0;
    &:not(:first-child) {
      border-top: solid 1px #efefef;
    }
  }
}
.transaction-info-item {
  padding: 5px 8px 5px 0;
  text-align: left;
  .item-title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 19px;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
  }
  .item-value {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
}
.receiver-title {
  padding: 5px 0;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

.f-1 {
  flex: 1;
}
.f-2 {
  flex: 2;
}

.trans-detail-modal {
  .modal-title {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0d234a;
  }
}
.notification {
  background-color: rgb(255, 224, 224);
  text-align: center;
  width: 100%;
  height: 50px;
  padding-top: 15px;
  span {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: $primaryColor;
  }
}

.gift-notification {
  background-color: #e5edf5;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.groupThumb {
  // height: 100%;
  height: 70px;
  // max-height: 70px;
  // min-height: 70px;
  width: 100%;
  // max-width: 320px;
  &__img {
    min-height: 100%;
    height: 100%;
    width: 100%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // @media screen and (min-width: map-get($grid-breakpoints, "md")) {
  }

  &__iconZoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    width: 165px;
  }
}

.periodInfo {
  font-size: 18px;
  line-height: 1.22;
  color: #333333;
  font-weight: 700;
}

.listPeriodInfo {
  // padding-bottom: 15px;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px;
    // border: solid 1px #e5e5e5;
    // border-bottom: none;
    &--win {
      background-color: #f7ede2;
    }

    // &:first-child {
    //   border-top-left-radius: 3px;
    //   border-top-right-radius: 3px;
    // }
    // &:last-child {
    //   border-bottom-left-radius: 3px;
    //   border-bottom-right-radius: 3px;
    //   border-bottom: solid 1px #e5e5e5;
    // }

    &__time {
      font-size: 0.875rem;
    }

    &__status {
      font-size: 0.75rem;
      font-weight: bold;
      border: solid 1px #999999;
      background-color: #ffffff;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 30px;
      &--win {
        background-color: #d31904;
        color: #ffffff;
        border: none;
      }
      &--canceled {
        background-color: #dbdbdb;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .content-card {
    width: auto;
  }
  .ticket-summary {
    .game-image {
      text-align: center;
      margin-bottom: 10px;
    }
    .order-detail {
      padding-left: 0;
    }
    .order-note {
      margin-top: 0;
    }
    .ticket-img {
      margin-top: 12px;
      img {
        width: 100%;
      }
    }
  }
  .transaction-info-row,
  .receiver-info {
    padding: 10px 0 !important;
    flex-direction: column;
  }
  .transaction-info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .item-value {
      flex: 1;
      padding-left: 10px;
      text-align: right;
    }
  }
  .receiver-title {
    margin-bottom: 0;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
  .ticket-summary {
    .order-detail {
      padding-right: 0;
    }
    .order-detail-block {
      display: block !important;

      .order-info {
        text-align: right;
      }
    }
  }

  .ticket-img {
    img {
      width: 100%;
    }

    &.customWidth {
      margin-top: 10px;
      max-width: 344px;
      margin: 0 auto;

      .groupThumb {
        height: 90px;
      }
    }
    &.custom-height {
      height: 90px;
      overflow: hidden;
    }
  }
}

.maxCorrect {
  background-color: #d68c00 !important;
}

.customNumber {
  width: 40px;
  height: 40px;
  @media screen and (max-width: 365px) {
    width: 32px;
    height: 32px;
  }
}
.traditionalNumber {
  border-top: 1px solid #e5e5e5;
  font-weight: bold;
  font-size: 50px;
  line-height: 68px;
  text-align: center;
  position: relative;
  padding: 10px 0 0;
  letter-spacing: 0.2em;
  .prefix {
    color: #999999;
  }
}
.traditionalCount {
  text-align: center;
  .countdown {
    color: #d31904;
    font-weight: 700;
  }
}
.groupDetail {
  padding-bottom: 20px;
  &__item {
    padding: 10px 0;
    border-bottom: 1px solid #efefef;
  }

  & > div:last-child &__item {
    border-bottom: none;
  }
  &__number {
    letter-spacing: 0.2em;
  }
}
.resultTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}
.resultTraditional {
  tr,
  td {
    border-bottom: 1px solid #e5e5e5;
    line-height: 27px;
  }
  td {
    padding: 10px 0;
  }
  &__numberrow {
    line-height: 2em !important;
  }
  &__number {
    font-size: 20px;
    font-weight: 700;
    margin: 0 12px;
    display: inline-block;
  }
  &__name {
    width: 100px;
    text-align: left;
  }
  &__reward {
    text-align: right;
  }
}
.traditionalReward {
  margin-top: 8px;
  padding: 20px;
  background-color: #f7ede2;
  color: #d31904;
  &__total {
    font-size: 30px;
    font-weight: 700;
    line-height: 41px;
  }
  table {
    color: #333;
    tr,
    td {
      border-bottom: 1px solid #999;
      line-height: 27px;
    }
    tr:last-child {
      border-bottom: none;
      td {
        border-bottom: none;
      }
    }
    td {
      padding: 10px 0;
    }
  }
  &__amount {
    text-align: right;
  }
}
</style>

<script>
import HLightBox from '@/components/HLightBox';
import CommonModal from '@/components/CommonModal.vue';
import Spinner from '@/components/Spinner.vue';
// import NumberItem from '../Cart/NumberItem';
import html2canvas from 'html2canvas';
import { isMobile, isIOS } from 'mobile-device-detect';
import CensorData from '@/components/CensorData';
import { BUS_EVENT, SHAREFB_TYPE, TICKET_MESSAGE_ENUM } from '../../common/global';
// import Max3dProCustom from './Max3dProCustom.vue';
import dayjs from 'dayjs';

// import download from 'download';

export default {
  layouts: 'Deposit',
  props: {
    ticketDetail: {
      type: Array,
      default: () => []
    },
    ticketInfo: {
      type: Object,
      default: () => ({
        id: '',
        game_img: '',
        lottery_time: '',
        ticket_img: [{}],
        total: '',
        notice: '',
        status: '',
        game_id: '',
        periods: []
      })
    },
    orderInfo: {
      type: Object,
      default: () => ({})
    },
    giftInfo: {
      type: Object,
      default: () => null
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    isShowHr: {
      type: Boolean,
      default: true
    }
  },
  components: {
    // LightBox,
    HLightBox,
    Spinner,
    // NumberItem,
    CensorData,
    // Max3dProCustom,
    CommonModal
  },
  data() {
    return {
      // hihixd: true,
      ticketMapping: ['A', 'B', 'C', 'D', 'E', 'F'],
      // ticketInfo: {
      //   game_img: '',
      //   lottery_time: '',
      //   ticket_img: [{}],
      //   total: '',
      //   notice: '',
      //   status: '',
      //   game_id: ''
      // },
      // ticketDetail: [],
      // ticketResult: [],
      ticketCustomResult: {
        big: 0,
        small: 0,
        even: 0,
        odd: 0
      },
      // orderInfo: {},
      // ticketId: this.$route.params.ticketId,
      // isLoading: true,
      statusReward: {
        win: 'Trúng Thưởng',
        notwin: 'Không Trúng',
        wait: 'Chờ xử lý',
        print: 'Chờ xổ',
        canceled: 'Đã hủy'
      },
      modelPrint: false,
      imgPrint: null,
      imageLoading: false,
      canPrint: true,
      canShare: true,
      canSave: true,
      btnDisabled: true,
      // giftInfo: null,
      SHAREFB_TYPE,
      traditionalCountDown: null,
      traditionalCountDownTimer: null,
      traditionalPriceList: [
        '100.000đ',
        '200.000đ',
        '400.000đ',
        '1.000.000đ',
        '3.000.000đ',
        '10.000.000đ',
        '15.000.000đ',
        '30.000.000đ',
        '2.000.000.000đ'
      ],
      isOpenGroupTicketDetail: false,
      currentHlBoxIndex: 0
    };
  },
  beforeCreate() {},
  watch: {
    // $route(to, from) {
    //   if (to.params.ticketId != from.params.ticketId) {
    //     this.ticketId = to.params.ticketId;
    //     this.updateOrderId(this.ticketId);
    //     this.getTicketDetail();
    //   }
    // },
    modelPrint(val) {
      this.handleToggleModal(val);
      val || this.close();
    },
    isOpenmodalTransaction: function(newVal) {
      this.handleToggleModal(newVal);
    }
  },
  created() {
    this.$bus.$on(BUS_EVENT.UPDATE_ORDER_REPLY, this.handleUpdateReply);

    // this.getTicketDetail();
    this.getPrintSaveShareStatus();
    // setTimeout(() => {
    //   console.log(this.btnDisabled);
    //   this.btnDisabled = false;
    // }, 1000);
  },
  destroyed() {
    this.$bus.$off(BUS_EVENT.UPDATE_ORDER_REPLY, this.handleUpdateReply);
    clearInterval(this.traditionalCountDownTimer);
  },
  methods: {
    getImage() {
      let node = document.getElementById('invoice');
      node.style.opacity = '1';
      let vue = this;
      html2canvas(node)
        .then(function(canvas) {
          vue.imgPrint = canvas.toDataURL('image/png');
          const img = new Image();
          img.src = vue.imgPrint;
          document.getElementById('bill_img').appendChild(img);
          img.style.width = '100%';
          img.style.border = '1px solid gray';
          vue.imageLoading = false;
        })
        .catch(function(error) {
          console.error('oops, something went wrong!', error);
        })
        .finally(() => (node.style.opacity = '0'));
    },
    getPrintProductName() {
      if (
        this.ticketInfo.game_handler != 'keno' &&
        this.ticketInfo.game_handler != this.ticketDetail[0]?.product_handler
      )
        return this.getProductName();
      else return false;
    },
    getPrintSaveShareStatus() {
      this.canShare = isMobile && !isIOS;
      this.canPrint = !isMobile;
      this.canSave = !(isIOS && !window.ReactNativeWebView);
    },
    saveImage() {
      if (window.ReactNativeWebView) {
        this.sendMessageToApp(
          JSON.stringify({
            event: 'SAVE_IMAGE',
            data: { image: this.imgPrint }
          })
        );
      } else {
        const link = document.createElement('a');
        link.href = this.imgPrint;
        link.setAttribute('download', `ticket_${this.ticketInfo.display_ticket_id}`);
        document.body.appendChild(link);
        link.click();
        link.style.display = 'none';
      }
    },
    async shareImage() {
      const blob = await (await fetch(this.imgPrint)).blob();
      const file = new File([blob], `ticket_${this.ticketInfo.display_ticket_id}.png`, { type: blob.type });
      if (window.ReactNativeWebView) {
        const data = {
          message: `Biên nhận vé ${this.ticketInfo.display_ticket_id}`,
          url: this.imgPrint
        };
        this.sendMessageToApp(JSON.stringify({ event: 'SHARE_IMAGE', data }));
      } else {
        let shareData = {
          text: `Biên nhận vé ${this.ticketInfo.display_ticket_id}`,
          files: [file]
        };
        if (isIOS) shareData.url = this.imgPrint;
        // alert(navigator.canShare);
        if (navigator.share) {
          navigator.share(shareData);
        }
      }
    },
    printImage() {
      if (!isMobile) {
        document.getElementById('print_img').innerHTML = document.getElementById('invoice').innerHTML;
      } else {
        document.getElementById('print_img').innerHTML = '';
        const img = new Image();
        img.src = this.imgPrint;
        img.style.width = '100%';
        document.getElementById('print_img').appendChild(img);
      }
      window.print();
    },
    close() {
      this.modelPrint = false;
      document.getElementById('bill_img').innerHTML = '';
    },
    printBill() {
      window.scrollTo({ top: 0, behavior: 'auto' });
      this.imageLoading = true;
      this.modelPrint = true;
      setTimeout(() => {
        this.getImage();
      }, 500);
    },
    updateOrderId(id) {
      this.$bus.$emit('updateOrderIdMobile', id);
    },
    isCorrect(number, isBonusNumber) {
      return (
        this.ticketInfo.status == 1 &&
        this.ticketResult &&
        this.ticketResult.slice(0, isBonusNumber ? undefined : -1).filter((res) => parseInt(res) == parseInt(number))
          .length > 0
      );
    },
    openGallery() {
      this.$refs[`hLightbox_img_${this.ticketInfo.id}`].showImage(0);
    },
    getGameType(game_id) {
      return this.listGameHandlerById[game_id].handler;
    },
    getProductName() {
      return this.ticketDetail[0].product_name;
    },
    getDataNumber(data) {
      const { ticket_info: item } = data;
      const productHandler = this.getProductHandler(data);

      if (productHandler.match('head')) {
        return [NaN, ...item];
      }
      if (productHandler.match('tail')) {
        return [...item, NaN];
      }
      if (productHandler.includes('max3dprocombo')) {
        let ticketArray = [];
        for (let i = 0; 3 * i < item.length; i++) {
          ticketArray.push(item.slice(i * 3, i * 3 + 3).join(''));
        }
        return ticketArray;
      }
      return item;
    },
    // handleShareFb() {
    //   const { type, code, name, username } = this.giftInfo || {};
    //   const { order_id } = this.ticketDetail?.[0] || {};

    //   let paymentData = {
    //     gift_code: code,
    //     gift_message: this.orderInfo.gift_message,
    //     name,
    //     gift_type: '',
    //     total_reward: this.totalPrize,
    //     username,
    //     ticket_id: this.ticketInfo.display_ticket_id,
    //     order_id
    //   };

    //   if (type === SHAREFB_TYPE.GIFT) {
    //     if (this.hasReward) {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFT_WIN;
    //     } else {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFT;
    //     }
    //   } else if (type === SHAREFB_TYPE.GIFTED) {
    //     if (this.hasReward) {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFTED_WIN;
    //     } else {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFTED;
    //     }
    //   }
    //   if (!paymentData.gift_type) {
    //     return;
    //   }
    //   this.$store.commit('setDataRequestPayment', paymentData);
    //   this.$store.commit('setIsOpenModalShare', true);
    // },
    handleSendThank() {
      const { name, username } = this.giftInfo || {};
      const { display_ticket_id } = this.ticketInfo || {};
      let thankData = {
        name,
        username,
        display_ticket_id,
        type: TICKET_MESSAGE_ENUM.BUY_REPLY
      };
      this.$store.commit('setPopupSendThankData', thankData);

      this.isOpenSendThank = true;
    },
    handleUpdateReply(data) {
      this.$set(this.giftInfo, 'buy_reply_message', data);
    },
    startTraditionalCountDown() {
      if (!this.isTraditional) return;
      let timeRemaining = dayjs(this.ticketInfo.lottery_time).valueOf() - CustomDate.now();
      if (timeRemaining < 0) {
        timeRemaining = 0;
        this.traditionalCount = null;
      } else {
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        this.traditionalCountDown = `${days > 0 ? days + ' ngày' : ''} ${hours}:${minutes}:${seconds}`;
        clearInterval(this.traditionalCountDownTimer);
        this.traditionalCountDownTimer = setInterval(() => {
          this.startTraditionalCountDown();
        }, 1000);
      }
    },
    openGroupTicketDetail() {
      this.isOpenPopupChooseTicket = false;
      this.isOpenGroupTicketDetail = true;
    },
    handleHideGroupTicketDetail() {
      this.isOpenPopupChooseTicket = true;
    },
    handlePrevPeriod() {
      this.currentHlBoxIndex = this.currentHlBoxIndex - 1;
    },
    handleNextPeriod() {
      this.currentHlBoxIndex = this.currentHlBoxIndex + 1;
    }
  },
  computed: {
    productHandler() {
      return this.getProductHandler(this.ticketDetail[0]);
    },
    // isMax3dProCustom() {
    //   return ['max3dprocombo', 'max3dprocombine'].filter((type) => this.productHandler.includes(type))[0];
    // },
    isKeno() {
      return this.ticketInfo.game_id === this.$store.getters.getterGameId['keno'];
    },
    isPower() {
      return this.ticketInfo.game_id === this.$store.getters.getterGameId['power'];
    },
    isKenoInvest() {
      return this.isKeno && this.ticketInfo.order_type === 'keno_invest';
    },
    investStopRule() {
      if (this.orderInfo.invest_config) {
        let { stop_rule } = JSON.parse(this.orderInfo.invest_config);
        return stop_rule;
      }

      return null;
    },
    isShowStopRule() {
      if (!this.investStopRule) {
        return false;
      }

      return !!Object.keys(this.investStopRule).length;
    },
    isTraditional() {
      return this.ticketInfo.game_id === this.$store.getters.getterGameId['xsmn'];
    },
    isPrinted() {
      return this.ticketInfo.status == 1;
    },
    isOpenmodalTransaction: {
      get() {
        return this.$store.getters.getterIsOpenModalTransactionResult;
      },
      set(newVal) {
        this.$store.commit('setIsOpenModalTransactionResult', newVal);
      }
    },
    gameType() {
      return this.getGameType(this.ticketInfo.game_id);
    },
    listGameHandlerById() {
      return this.convertArrayToObject(Object.values(this.$store.getters.getterListGameHandler), 'id');
    },
    hasReward() {
      return this.ticketInfo.periods.findIndex((item) => item.reward) >= 0;
    },
    rewards() {
      const winTicketList = this.ticketDetail.filter((item) => item.reward) || [];
      let ticketRewards = [];
      winTicketList.map((ticket) => {
        ticket.reward.forEach((re) => {
          let reward = ticketRewards.find((r) => r.title === re.title);
          if (reward) {
            reward = {
              title: re.title,
              value: [...reward.value, ticket.ticket_info.value],
              amount: reward.amount + re.amount
            };
          } else {
            ticketRewards.push({
              title: re.title,
              value: [ticket.ticket_info.value],
              amount: re.amount
            });
          }
        });
      });
      return ticketRewards;
    },
    hasResult() {
      return !!this.ticketInfo.returned_reward;
    },
    statusRewardComp() {
      if (!this.hasResult) {
        return this.isPrinted ? this.statusReward.print : this.statusReward.wait;
      }
      if (this.hasReward) {
        return this.statusReward.win;
      }
      return this.statusReward.notwin;
    },
    statusRewardCompByPeriod() {
      return (period) => {
        if (period.ticket_status === 2) {
          return this.statusReward.canceled;
        }
        if (!period.returned_reward) {
          return period.ticket_status === 1 ? this.statusReward.print : this.statusReward.wait;
        }
        if (period.reward) {
          return this.statusReward.win;
        }
        return this.statusReward.notwin;
      };
    },
    vipInfo() {
      return this.$store.getters.getterUserInfo.vip_data;
    },
    listAdditional() {
      return this.$store.getters.getterKenoCustomProductList;
    },
    totalPrize() {
      // return (
      //   this.ticketDetail.reduce((vR, ticket) => {
      //     let result = 0;
      //     if (ticket.reward && ticket.reward.length > 0) {
      //       result = ticket.reward.reduce((value, item) => item.amount + value, 0);
      //     }
      //     return vR + result;
      //   }, 0) || 0
      // );
      let totalReward = 0;
      this.ticketInfo.periods.map((p) => {
        totalReward += p.reward_amount;
      });
      return totalReward;
    },
    isOpenSendThank: {
      get() {
        return this.$store.getters.getterIsOpenSendThank;
      },
      set(newValue) {
        this.$store.commit('setIsOpenSendThank', newValue);
      }
    },
    isTabGift() {
      return this.giftInfo.type === SHAREFB_TYPE.GIFT;
    },
    isTabGifted() {
      return this.giftInfo.type === SHAREFB_TYPE.GIFTED;
    },
    reverseTicketResult() {
      return this.ticketResult.slice().reverse();
    },
    groupTicketNumber() {
      return this.ticketDetail[0]?.ticket_info.value?.substring(1);
    },
    ticketId() {
      return this.ticketInfo.display_ticket_id;
    },
    periodsWithResult() {
      return this.ticketInfo.periods.filter((period) => period.returned_reward === 1);
    },
    ticketResult() {
      return JSON.parse(this.periodsWithResult[this.periodsWithResult.length - 1]?.lottery_result || '[]');
    },
    listIndexExclude() {
      if (!this.ticketInfo.periods.length) return [];

      return this.ticketInfo.periods
        .map((item, index) => (item.ticket_status === 2 ? index : -1))
        .filter((item) => item >= 0);
    },

    ticketInfoPeriods() {
      return this.ticketInfo.periods.filter((item, index) => !this.listIndexExclude.includes(index));
    },
    ticketInfoTicketImg() {
      return this.ticketInfo.ticket_img
        .filter((item, index) => !this.listIndexExclude.includes(index))
        .map((value) => {
          if (value.src) {
            return value;
          } else {
            return {
              src: 'https://assets.tailoc68.vn/img/no-ticket-photo.png',
              thumb: 'https://assets.tailoc68.vn/img/no-ticket-photo.png'
            };
          }
        });
    },
    ticketInfoBackImageUrl() {
      return this.ticketInfo.back_image_url
        .filter((item, index) => !this.listIndexExclude.includes(index))
        .map((value) => {
          if (value.src) {
            return value;
          } else {
            return {
              src: 'https://assets.tailoc68.vn/img/no-ticket-photo.png',
              thumb: 'https://assets.tailoc68.vn/img/no-ticket-photo.png'
            };
          }
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    const listResetPagination = ['ve-da-mua', 've-da-tang', 've-duoc-tang'];

    if (!listResetPagination.includes(to?.name)) {
      this.$store.commit('setSavePagination', null);
    }
    next();
  }
};
</script>
<style lang="scss" scoped>
.bg_nowin {
  background-color: #f5f5f5;
}

.mt_item {
  margin-top: 10px;
}

.border-item {
  border: solid 1px #e5e5e5;
  border-radius: 3px;
}
.w-47 {
  width: 47%;
}
</style>
