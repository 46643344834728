<template>
  <div @click.stop="closeLightBox">
    <transition
      mode="out-in"
      name="vue-lb-content-transition"
      @afterEnter="enableImageTransition"
      @beforeLeave="disableImageTransition"
    >
      <div
        v-if="media && media.length > 0"
        v-show="lightBoxOn"
        ref="container"
        class="vue-lb-container vue-lb-container-custom"
      >
        <div class="vue-lb-content vue-lb-content-custom scrollbar">
          <div class="vue-lb-header align-items-center pb-md-3">
            <p class="font-20 color-black-1">Vé đã mua</p>
            <button v-if="closable" type="button" :title="closeText" class="vue-lb-button-close">
              <slot name="close">
                <HCloseIcon />
              </slot>
            </button>
            <!-- .vue-lb-button-close -->
          </div>
          <!-- .vue-lb-header -->

          <div class="vue-lb-figure vue-lb-figure-custom" @click.stop>
            <b-tabs
              class="tabs-custom"
              justified
              v-if="data.game_handler != 'keno' && data.back_image_url.length > 0 && media.length > 1"
            >
              <b-tab title="MẶT TRƯỚC" :active="select == 0" @click="select = 0"></b-tab>
              <b-tab title="MẶT SAU" :active="select == 1" @click="select = 1"></b-tab>
            </b-tabs>
            <transition v-if="media[select]" class="mt-2" mode="out-in" :name="modalImageTransitionName">
              <img
                :draggable="false"
                v-if="media[select].type !== 'video'"
                :key="media[select].src"
                v-lazy="{
                  src: media[select].src,
                  loading: media[select].src,
                  error: media[select].src
                }"
                :srcset="media[select].srcset || ''"
                class="vue-lb-modal-image vue-lb-modal-image-custom"
                :alt="media[select].caption"
              />
              <video
                v-else
                ref="video"
                :key="media[select].sources[0].src"
                :width="media[select].width"
                :height="media[select].height"
                :autoplay="media[select].autoplay"
                controls
              >
                <source
                  v-for="source in media[select].sources"
                  :key="source.src"
                  :src="source.src"
                  :type="source && source.type"
                />
              </video>
            </transition>
            <img :draggable="false" class="vue-lb-content-custom__logo custom-logo" src="../assets/img/In-ve.png" />

            <slot v-if="media[select]" name="customCaption">
              <div v-show="showCaption" class="vue-lb-info" v-html="media[select].caption" />
            </slot>
            <div class="d-flex justify-content-between" v-if="canPrev || canNext">
              <div
                class="text-uppercase nav-btns nav-btns__prev"
                :class="{ 'nav-btns__disabled': !canPrev }"
                @click="prevPeriod"
              >
                <img :draggable="false" src="../assets/img/icon/icon_prev.svg" alt="" class="mr-3 d-inline-block" />
                {{ textCustom.prev }}
              </div>
              <div
                class="text-uppercase nav-btns nav-btns__next"
                :class="{ 'nav-btns__disabled': !canNext }"
                @click="nextPeriod"
              >
                {{ textCustom.next }}
                <img :draggable="false" src="../assets/img/icon/icon_next.svg" alt="" class="ml-3 d-inline-block" />
              </div>
            </div>
            <div class="vue-lb-footer-custom text-left">
              <p class="pb-1" v-if="!isTraditional">
                Kỳ quay số:
                <span class="font-weight-600">#{{ data.period_no }} {{ getTimeZone(data.lottery_time) }}</span>
              </p>
              <p class="pb-1" v-else>
                Vé số kiến thiết:
                <span class="font-weight-600"
                  >{{ data.ticketDetail[0].product_name }} -
                  {{ formatDate(data.lottery_time, 'dddd ngày DD/MM/YYYY') }}</span
                >
              </p>
              <p class="pb-1">
                Bộ số: <span class="font-weight-600">{{ data.ticketDetail[0].ticket_info.value }}</span>
              </p>
              <p class="pb-1">
                Hình thức nhận vé: <span class="font-weight-600">{{ data.shipping_method.name }}</span>
              </p>
              <p class="pb-1">
                Họ và tên: <span class="font-weight-600">{{ data.receiver_name }}</span>
              </p>
              <p class="pb-1">
                CMND: <span class="font-weight-600">{{ data.receiver_id }}</span>
              </p>
              <p class="pb-1">
                Số điện thoại: <span class="font-weight-600">{{ data.receiver_phone }}</span>
              </p>
              <template v-if="!isTraditional">
                <div class="py-1 d-flex align-items-center flex-wrap" v-for="(item, index) in listTicket" :key="index">
                  <p class="mr-2">{{ ticketMapping[index] }}</p>
                  <p v-if="getProductHandler(item).includes('head')" class="font-weight-600 font-18">*</p>
                  <p
                    :class="{ correct: !!item.reward }"
                    class="font-weight-600 text-uppercase"
                    v-if="typeof item.ticket_info == 'string'"
                  >
                    {{ item.display_info }}
                  </p>
                  <p
                    :class="{
                      correct:
                        data.lottery_result &&
                        (isMaxGame
                          ? isCorrectMaxGame(i, item, JSON.parse(data.lottery_result))
                          : isCorrect(
                              JSON.parse(data.lottery_result),
                              itemChild,
                              isPower ? item.reward && item.reward[0].bonus_number : true
                            )),
                      'mr-2': !isMaxGame || (['max3d+', 'max3dpro'].includes(gameType) && (i + 1) % 3 === 0)
                    }"
                    v-else
                    v-for="(itemChild, i) in item.ticket_info"
                    :key="'child' + i"
                    class="font-weight-600 font-18"
                  >
                    {{ isMaxGame ? itemChild : formatNumber(itemChild) }}
                  </p>
                  <p v-if="getProductHandler(item).includes('tail')" class="font-weight-600 font-18">*</p>
                </div></template
              >
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import LeftArrowIcon from './LeftArrowIcon';
// import RightArrowIcon from './RightArrowIcon';
import HCloseIcon from './HCloseIcon';
// import VideoIcon from './VideoIcon';
let Hammer;
if (typeof window !== 'undefined') {
  Hammer = require('hammerjs');
}
export default {
  components: {
    // LeftArrowIcon,
    // RightArrowIcon,
    HCloseIcon
    // VideoIcon
  },
  props: {
    ticketMapping: { type: Array },
    ticketCustomResult: { type: Object },
    media: {
      type: Array,
      required: true
    },
    disableScroll: {
      type: Boolean,
      default: true
    },
    showLightBox: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    startAt: {
      type: Number,
      default: 0
    },
    nThumbs: {
      type: Number,
      default: 7
    },
    showThumbs: {
      type: Boolean,
      default: true
    },
    // Mode
    autoPlay: {
      type: Boolean,
      default: false
    },
    autoPlayTime: {
      type: Number,
      default: 3000
    },
    siteLoading: {
      type: String,
      default: ''
    },
    showCaption: {
      type: Boolean,
      default: false
    },
    lengthToLoadMore: {
      type: Number,
      default: 0
    },
    closeText: {
      type: String,
      default: 'Close (Esc)'
    },
    previousText: {
      type: String,
      default: 'Previous'
    },
    nextText: {
      type: String,
      default: 'Next'
    },
    previousThumbText: {
      type: String,
      default: 'Previous'
    },
    nextThumbText: {
      type: String,
      default: 'Next'
    },
    data: {
      type: Object
    },
    isPower: Boolean,
    gameType: {
      type: String
    },
    canNext: Boolean,
    canPrev: Boolean,
    textCustom: {
      type: Object,
      default: () => ({
        prev: 'Kỳ trước',
        next: 'Kỳ sau'
      })
    },
    isEventWipe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      select: this.startAt,
      lightBoxOn: this.showLightBox,
      timer: null,
      modalImageTransitionName: 'vue-lb-modal-image-no-transition'
      // listTicket: []
    };
  },
  computed: {
    thumbIndex() {
      const halfDown = Math.floor(this.nThumbs / 2);
      if (this.select >= halfDown && this.select < this.media.length - halfDown)
        return {
          begin: this.select - halfDown + (1 - (this.nThumbs % 2)),
          end: this.select + halfDown
        };
      if (this.select < halfDown)
        return {
          begin: 0,
          end: this.nThumbs - 1
        };
      return {
        begin: this.media.length - this.nThumbs,
        end: this.media.length - 1
      };
    },
    imagesThumb() {
      if (this.siteLoading) {
        return this.media.map(({ thumb, type }) => ({
          src: thumb,
          type,
          loading: this.siteLoading,
          error: this.siteLoading
        }));
      }
      return this.media.map(({ thumb, type }) => ({
        src: thumb,
        type
      }));
    },
    isMaxGame() {
      return ['max3d', 'max3d+', 'max4d', 'max3dpro'].includes(this.gameType);
    },
    isTraditional() {
      return this.gameType === 'xsmn';
    },
    listTicket() {
      return [...this.data.ticketDetail];
    }
  },
  watch: {
    lightBoxOn(value) {
      if (document != null) {
        this.onToggleLightBox(value);
      }
    },
    select() {
      this.$emit('onImageChanged', this.select);
      if (this.select >= this.media.length - this.lengthToLoadMore - 1) this.$emit('onLoad');
      if (this.select === this.media.length - 1) this.$emit('onLastIndex');
      if (this.select === 0) this.$emit('onFirstIndex');
      if (this.select === this.startAt) this.$emit('onStartIndex');
    }
  },
  mounted() {
    // console.log(this.data);
    // console.log(this.ticketMapping);
    // this.listTicket = [...this.data.ticketDetail];

    if (this.autoPlay) {
      this.timer = setInterval(() => {
        this.nextImage();
      }, this.autoPlayTime);
    }
    this.onToggleLightBox(this.lightBoxOn);

    if (this.$refs.container && this.isEventWipe) {
      const hammer = new Hammer(this.$refs.container);
      hammer.on('swiperight', () => {
        this.previousImage();
      });
      hammer.on('swipeleft', () => {
        this.nextImage();
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.addKeyEvent);
    if (this.autoPlay) {
      clearInterval(this.timer);
    }
  },
  methods: {
    isCorrect(listTicketResult, number, isBonusNumber) {
      return (
        listTicketResult && listTicketResult.slice(0, isBonusNumber ? undefined : -1).some((m) => parseInt(m) == number)
      );
    },
    onLightBoxOpen() {
      this.$emit('onOpened');
      if (this.disableScroll) {
        document.querySelector('html').classList.add('no-scroll');
      }
      document.querySelector('body').classList.add('vue-lb-open');
      document.addEventListener('keydown', this.addKeyEvent);
      if (this.$refs.video && this.$refs.video.autoplay) {
        this.$refs.video.play();
      }
    },
    onLightBoxClose() {
      this.$emit('onClosed');
      if (this.disableScroll) {
        document.querySelector('html').classList.remove('no-scroll');
      }
      document.querySelector('body').classList.remove('vue-lb-open');
      document.removeEventListener('keydown', this.addKeyEvent);
      if (this.$refs.video) {
        this.$refs.video.pause();
        this.$refs.video.currentTime = 0;
      }
    },
    onToggleLightBox(value) {
      if (value) {
        this.onLightBoxOpen();
      } else {
        this.onLightBoxClose();
      }
    },
    showImage(index) {
      this.$set(this, 'select', index);
      this.$set(this, 'lightBoxOn', true);
    },
    addKeyEvent(event) {
      if (event.keyCode === 37) this.previousImage(); // left arrow
      if (event.keyCode === 39) this.nextImage(); // right arrow
      if (event.keyCode === 27) this.closeLightBox(); // esc
    },
    closeLightBox() {
      if (!this.closable) return;
      this.$set(this, 'lightBoxOn', false);
    },
    nextImage() {
      this.$set(this, 'select', (this.select + 1) % this.media.length);
    },
    previousImage() {
      this.$set(this, 'select', (this.select + this.media.length - 1) % this.media.length);
    },
    enableImageTransition() {
      this.$set(this, 'modalImageTransitionName', 'vue-lb-modal-image-transition');
    },
    disableImageTransition() {
      this.$set(this, 'modalImageTransitionName', 'vue-lb-modal-image-no-transition');
    },
    prevPeriod() {
      if (this.canPrev) this.$emit('prevPeriod');
    },
    nextPeriod() {
      if (this.canNext) this.$emit('nextPeriod');
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-logo {
  margin-top: -30px;
}
.correct {
  color: #d31904;
}

.vue-lb-content-custom {
  width: 451px;
  padding: 1rem 1.25rem 1.25rem 1.25rem;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 0;
  background-color: #ffffff;

  max-height: 95vh;
  overflow: overlay;

  .vue-lb-footer-custom {
    padding: 0.6rem 0 0.75rem;
    // position: absolute;
    // bottom: 0;
    color: $colorBlack;
    width: 100%;
    //   height: 244px;
    background: $colorWhite;
    font-size: 0.875rem;
  }

  img {
    &.vue-lb-modal-image-custom {
      cursor: auto;
      // max-height: 100%;
      max-height: 600px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__logo {
    width: 100%;
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .vue-lb-container-custom {
    padding: 0;
    .vue-lb-content-custom {
      // height: 100%;
      padding: 1rem;
      border-radius: 0;
      margin-bottom: 0;
    }
  }
}
@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .vue-lb-container-custom {
    .vue-lb-content-custom {
      // height: 100%;
      height: 100%;
      max-height: 100%;
      width: 100%;
    }
  }
}
.nav-btns {
  line-height: 40px;
  margin: 10px 0 0;
  padding: 0 10px;
  color: #1f4f98;
  font-weight: 600;
  cursor: pointer;
  &__prev {
    padding-left: 0;
  }
  &__next {
    padding-right: 0;
  }
  &__disabled {
    opacity: 0.5;
  }
}
</style>
