<template>
  <div class="-mx-15px -mt-20px">
    <div v-if="firstTicketDetail" class="section-header d-none d-lg-flex">
      <div class="header-title">
        Vé #{{ firstTicketDetail.ticketInfo && firstTicketDetail.ticketInfo.display_ticket_id }}
        <!-- <span v-if="!giftInfo">
          <img src="@/assets/img/icon/icon_info.svg" alt class="title-btn" @click="isOpenmodalTransaction = true" />
        </span> -->
      </div>
      <div class="header-link">
        <router-link :to="$route.meta.redirectBack">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g transform="translate(-1095 -280)">
              <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
              <path
                d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
                transform="translate(1096.98 290.313)"
                fill="#666"
              />
            </g>
          </svg>
          {{ $route.meta.redirectTitle }}
        </router-link>
      </div>
    </div>
    <hr class="d-none d-lg-block" />

    <!-- <div v-if="firstTicketDetail.giftInfo" class="gift-notification">
      <div>
        <div class="listPeriodInfo__item__time " v-if="firstTicketDetail.giftInfo.type == 'GIFTED'">
          Bạn được {{ firstTicketDetail.giftInfo.name }} ({{ firstTicketDetail.giftInfo.username }}) tặng vé số
          {{
            firstTicketDetail.orderInfo.gift_message ? `với lời nhắn "${firstTicketDetail.orderInfo.gift_message}"` : ''
          }}
        </div>
        <div class="listPeriodInfo__item__time " v-else-if="firstTicketDetail.giftInfo.type == 'GIFT'">
          Bạn đã tặng vé số cho {{ firstTicketDetail.giftInfo.code }}
          {{
            firstTicketDetail.orderInfo.gift_message ? `với lời nhắn "${firstTicketDetail.orderInfo.gift_message}"` : ''
          }}
        </div>
        <em class="color-blue" v-if="isTabGifted && firstTicketDetail.giftInfo.buy_reply_message"
          >Bạn đã gửi cảm ơn: "{{ firstTicketDetail.giftInfo.buy_reply_message }}"</em
        >
        <em class="color-blue" v-if="isTabGift && firstTicketDetail.giftInfo.buy_reply_message"
          >{{ firstTicketDetail.giftInfo.code }} đã cảm ơn: "{{ firstTicketDetail.giftInfo.buy_reply_message }}"</em
        >
      </div>

      <div class="d-flex">
        <button
          v-if="!firstTicketDetail.giftInfo.buy_reply_message && isTabGifted"
          @click="handleSendThank"
          class="btn btnSendThank text-nowrap mr-2"
        >
          gửi cảm ơn
        </button>
        <button @click="handleShareFb" class="btn btnShareFb text-nowrap">
          <img src="@/assets/img/icon/icon_invite-fb.svg" alt="icon fb" /> chia sẻ
        </button>
      </div>
    </div> -->

    <b-card
      class="content-card mb-3 border-0"
      v-if="listTransactionDetail.length > 0 && listTransactionDetail[0].ticketInfo.order_type === 'keno_bulk'"
    >
      <div class="ticket-summary">
        <div class="game-image">
          <img :src="listTransactionDetail[0].ticketInfo.game_img" alt />
        </div>
        <div class="ml-3 mt-2 d-none d-md-block">
          <div class="order-info">
            <span class="text-nowrap">
              Kỳ #{{ listTransactionDetail[0].ticketInfo.period_no }} -
              {{ formatDate(listTransactionDetail[0].ticketInfo.lottery_time, 'HH:mm DD/MM/YYYY') }}
              <span v-if="listTransactionDetail[0].ticketInfo.periods.length - 1">
                và {{ listTransactionDetail[0].ticketInfo.periods.length - 1 }} kỳ khác</span
              >
            </span>
          </div>
          <div class="order-info">
            <span class="text-nowrap">
              {{ calcAmountKenoCombo(kenoComboInfo.length, kenoCombo) }} bộ số (Bao {{ kenoComboInfo.length }} bậc
              {{ kenoCombo }})
            </span>
          </div>
        </div>
        <div data-v-f0172d6e="" class="ticket-info text-right ml-auto">
          <div data-v-f0172d6e="" class="ticket-price">
            {{ formatPrice(listTransactionDetail[0].orderInfo.product_total_price) }}
          </div>
          <button
            class="btn btnPrimary btnPrimary--contained w-xs-100 font-weight-600 d-none d-md-block"
            @click="buyNow"
          >
            Mua lại vé
          </button>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-12 col-md-9 d-md-none">
            <div class="order-info">
              <span class="text-nowrap">
                Kỳ #{{ listTransactionDetail[0].ticketInfo.period_no }} -
                {{ formatDate(listTransactionDetail[0].ticketInfo.lottery_time, 'HH:mm DD/MM/YYYY') }}
                <span v-if="listTransactionDetail[0].ticketInfo.periods.length - 1">
                  và {{ listTransactionDetail[0].ticketInfo.periods.length - 1 }} kỳ khác</span
                >
              </span>
            </div>
            <div class="order-info">
              <span class="text-nowrap">
                {{ calcAmountKenoCombo(kenoComboInfo.length, kenoCombo) }} bộ số (Bao {{ kenoComboInfo.length }} bậc
                {{ kenoCombo }})
              </span>
            </div>
          </div>
          <!-- <div class="d-none d-md-flex col-md-12 justify-content-end">
            <button class="btn btnPrimary btnPrimary--contained w-xs-100 font-weight-600" @click="buyNow">
              Mua lại vé
            </button>
          </div> -->
        </div>
        <!-- <hr /> -->
        <!-- <div class="ticket-result d-flex flex-wrap mt-1">
          <NumberItem
            v-for="number in kenoComboInfo"
            :key="'ticket_info_' + ticketId + '_' + number"
            :number="number"
            class="spacingNumber"
            :isActive="false"
          />
        </div>
        <button
          class=" d-md-none w-100 mt-2 btn btnPrimary btnPrimary--contained w-xs-100 font-weight-600"
          @click="buyNow"
        >
          Mua lại vé
        </button> -->
      </div>
    </b-card>
    <TransactionDetail
      v-for="(item, index) in listTransactionDetail"
      :key="index"
      :ticketDetail="item.ticketDetail"
      :ticketInfo="item.ticketInfo"
      :orderInfo="item.orderInfo"
      :giftInfo="item.giftInfo"
      :isLoading="isLoading"
      :isShowHr="index !== 0"
    />
  </div>
</template>

<style lang="scss" scoped>
.gift-notification {
  background-color: #e5edf5;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.btnPrimary {
  width: 125px;
  height: 40px;
}
</style>

<script>
import OrderService from '@/api/order';
import TransactionDetail from './TransactionDetail.vue';
import { SHAREFB_TYPE, TICKET_MESSAGE_ENUM } from '../../common/global';

export default {
  layouts: 'Deposit',
  components: {
    TransactionDetail
  },
  data() {
    return {
      isLoading: true,
      ticketId: this.$route.params.ticketId,
      listTransactionDetail: [],
      kenoComboInfo: null,
      kenoCombo: 0
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.ticketId != from.params.ticketId) {
        this.ticketId = to.params.ticketId;
        this.updateOrderId(this.ticketId);
        this.getTicketDetail();
      }
    }
  },
  created() {
    this.getTicketDetail();
  },
  destroyed() {
    // this.$bus.$off(BUS_EVENT.UPDATE_ORDER_REPLY, this.handleUpdateReply);
  },
  mounted() {},
  methods: {
    async getTicketDetail() {
      this.isLoading = true;
      try {
        const res = await OrderService.getGroupTicketDetail(this.ticketId);
        if (res.data.status) {
          const listData = [];
          for (const ticketInfo of res.data.data.ticket_info) {
            let customData = {};

            let {
              game_img,
              game_handler,
              lottery_time,
              lottery_result,
              image_url,
              back_image_url,
              ticket_price,
              game_id,
              period_no,
              notice,
              status,
              printed_at,
              display_ticket_id,
              returned_reward,
              details,
              order_type,
              periods,
              id,
              bulk_info
            } = ticketInfo;
            let kenoComboNumber = new Set();

            customData.ticketDetail = details.map((ticket) => {
              let {
                id,
                product_name,
                price,
                ticket_info,
                status,
                reward,
                game_id,
                product_id,
                ticket_id,
                order_id,
                ticket_name
              } = ticket;
              const product_handler = this.getProductHandler({ game_id, product_id });
              let display_info = '';
              let display_type = '';
              let ticketParsable = true;
              // if (typeof ticket_info === 'object') {
              //   ticketParsable = true;
              // } else {
              //   let findAdditional = this.listAdditional.find((additional) => additional.text == ticket_info);
              //   display_info = findAdditional ? findAdditional.name : product_name;
              //   product_name = 'Bổ sung';
              //   ticketParsable = false;
              // }
              try {
                ticket_info = JSON.parse(ticket_info);
                ticketParsable = true;
              } catch {
                let findAdditional = this.listAdditional.find((additional) => additional.text == ticket_info);
                display_info = findAdditional ? findAdditional.name : product_name;
                product_name = 'Bổ sung';
                ticketParsable = false;
              }

              if (ticketParsable) {
                switch (game_handler) {
                  case 'max3d':
                    display_info = ticket_info.join('');
                    break;
                  case 'max3d+':
                    display_info = [ticket_info.slice(0, 3), ticket_info.slice(3, 6)].map((n) => n.join('')).join(' ');
                    break;
                  case 'max4d':
                    if (product_handler.includes('head')) {
                      display_info = `*${ticket_info.join('')}`;
                      display_type = 'X10';
                    } else if (product_handler.includes('tail')) {
                      display_info = `${ticket_info.join('')}*`;
                      display_type = 'X10';
                    } else if (product_handler.includes('combine')) {
                      display_info = ticket_info.join('');
                      display_type = product_handler.replace('max4dcombine', '');
                    } else if (product_handler.includes('combo')) {
                      display_info = ticket_info.join('');
                      display_type = `X${product_handler.replace('max4dcombo', '')}`;
                    } else {
                      display_info = ticket_info.join('');
                    }
                    break;
                  case 'max3dpro':
                    display_info = [ticket_info.slice(0, 3), ticket_info.slice(3, 6)].map((n) => n.join('')).join(' ');
                    break;
                  case 'power':
                  case 'mega':
                    display_info = ticket_info.map((n) => this.formatNumber(n)).join(' ');
                    break;
                  case 'keno':
                    display_info = ticket_info.map((n) => this.formatNumber(n));
                    display_info =
                      ticket_info.length > 5
                        ? [display_info.slice(0, 5), display_info.slice(5, 10)].map((info) => info.join(' ')).join('\n')
                        : display_info.join(' ');
                    display_type = ticket_info.length;
                    break;
                  default:
                    break;
                }
                if (ticket_info.image_url) {
                  image_url = ticket_info.image_url;
                }
                if (ticket_info.length && order_type === 'keno_bulk') {
                  this.kenoCombo = ticket_info.length;
                  ticket_info.forEach((number) => {
                    kenoComboNumber.add(number);
                  });
                }
              }
              return {
                game_id,
                id,
                product_name,
                product_handler,
                price,
                status,
                product_id,
                ticket_info,
                reward,
                display_info,
                display_type,
                ticket_id,
                order_id,
                image_url,
                ticket_name
              };
            });
            customData.ticketInfo = {
              game_id,
              game_handler,
              game_img,
              lottery_time,
              lottery_result,
              ticket_img: image_url?.map((ima) => ({ thumb: ima, src: ima })) || [],
              back_image_url:
                typeof back_image_url === 'string'
                  ? [{ thumb: back_image_url, src: back_image_url }]
                  : back_image_url?.map((imb) => ({ thumb: imb, src: imb })) || [],
              total: ticket_price * periods.length,
              period_no,
              notice,
              status,
              printed_at,
              display_ticket_id,
              returned_reward,
              id,
              periods,
              order_type
            };

            if (order_type === 'keno_bulk' && !this.kenoComboInfo) {
              if (bulk_info) {
                const parsedBulkInfo = JSON.parse(bulk_info);
                this.kenoCombo = parsedBulkInfo.product_slot_count;
                this.$set(this, 'kenoComboInfo', parsedBulkInfo.ticket_info);
              } else {
                this.$set(this, 'kenoComboInfo', [...kenoComboNumber]);
              }
            }

            customData.orderInfo = { ...res.data.data.order_info };
            customData.giftInfo = res.data.data.gift_info ? { ...res.data.data.gift_info } : null;

            listData.push(customData);
          }
          this.listTransactionDetail = listData;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    updateOrderId(id) {
      this.$bus.$emit('updateOrderIdMobile', id);
    },
    buyNow() {
      const { ticketInfo, ticketDetail } = this.listTransactionDetail[0];

      if (!ticketInfo?.game_id || !this.kenoComboInfo?.length) return;

      const { game_id, product_id, product_handler, ticket_name, price } = ticketDetail[0];

      this.$store.commit('setBuyTicketAgainData', [
        {
          game_id,
          product_id,
          product_handler,
          ticket_info: this.kenoComboInfo,
          ticket_name,
          price,
          isKenoCombo: true
        }
      ]);

      this.$router.push(this.getListGameURL[ticketInfo.game_id].url);
    },
    // handleShareFb() {
    //   const { giftInfo, ticketDetail, orderInfo, ticketInfo } = this.firstTicketDetail;
    //   const { type, code, name, username } = giftInfo || {};
    //   const { order_id } = ticketDetail?.[0] || {};

    //   const hasReward = this.listTransactionDetail.some((item) => {
    //     return item.ticketDetail.findIndex((item) => item.reward) >= 0;
    //   });

    //   const totalPrize = this.listTransactionDetail.reduce(
    //     (sum, item) =>
    //       sum +
    //       item.ticketDetail.reduce((vR, ticket) => {
    //         let result = 0;
    //         if (ticket.reward && ticket.reward.length > 0) {
    //           result = ticket.reward.reduce((value, item) => item.amount + value, 0);
    //         }
    //         return vR + result;
    //       }, 0),
    //     0
    //   );

    //   let paymentData = {
    //     gift_code: code,
    //     gift_message: orderInfo.gift_message,
    //     name,
    //     gift_type: '',
    //     total_reward: totalPrize,
    //     username,
    //     ticket_id: ticketInfo.display_ticket_id,
    //     order_id
    //   };
    //   if (type === SHAREFB_TYPE.GIFT) {
    //     if (hasReward) {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFT_WIN;
    //     } else {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFT;
    //     }
    //   } else if (type === SHAREFB_TYPE.GIFTED) {
    //     if (hasReward) {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFTED_WIN;
    //     } else {
    //       paymentData.gift_type = SHAREFB_TYPE.GIFTED;
    //     }
    //   }
    //   if (!paymentData.gift_type) {
    //     return;
    //   }
    //   this.$store.commit('setDataRequestPayment', paymentData);
    //   this.$store.commit('setIsOpenModalShare', true);
    // },
    handleSendThank() {
      const listDisplayTicket = this.listTransactionDetail.map((item) => item.ticketInfo?.display_ticket_id);
      const { giftInfo, ticketInfo } = this.firstTicketDetail;

      const { name, username } = giftInfo || {};
      const { display_ticket_id } = ticketInfo || {};
      let thankData = {
        name,
        username,
        display_ticket_id,
        type: TICKET_MESSAGE_ENUM.BUY_REPLY,
        listDisplayTicket
      };
      this.$store.commit('setPopupSendThankData', thankData);
      this.isOpenSendThank = true;
    }
  },
  computed: {
    firstTicketDetail() {
      return this.listTransactionDetail?.[0] ?? {};
    },
    listAdditional() {
      return this.$store.getters.getterKenoCustomProductList;
    },
    getListGameURL() {
      return this.convertArrayToObject(this.$store.getters.getterGameUrl, 'id');
    },
    isTabGift() {
      return this.firstTicketDetail?.giftInfo?.type === SHAREFB_TYPE.GIFT;
    },
    isTabGifted() {
      return this.firstTicketDetail?.giftInfo?.type === SHAREFB_TYPE.GIFTED;
    },
    isOpenSendThank: {
      get() {
        return this.$store.getters.getterIsOpenSendThank;
      },
      set(newValue) {
        this.$store.commit('setIsOpenSendThank', newValue);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    const listResetPagination = ['ve-da-mua', 've-da-tang', 've-duoc-tang'];

    if (!listResetPagination.includes(to?.name)) {
      this.$store.commit('setSavePagination', null);
    }
    next();
  }
};
</script>
